import { APP_INITIALIZER, ErrorHandler, ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { LoggerService } from './services/logger/logger.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { AuthenticationService } from './services/authentication.service';
import { ShopRouteNavigationService } from './routing/shop-route-navigation.service';
import { ENVIRONMENT, WINDOW } from './services/injection-tokens';
import { environment } from '../../environments/environment';
import { CoolHttpModule } from '@angular-cool/http';
import { CoolStorageModule } from '@angular-cool/storage';
import { TranslateModule } from '@ngx-translate/core';
import { TranslationService } from './services/translation/translation.service';
import { StartupService, startupServiceFactory } from './services/startup/startup.service';
import { GlobalErrorHandler } from './services/logger/global-error-handler';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DateProvider } from './services/date.provider';
import { UnauthenticatedInterceptor } from './services/http/unauthenticated.interceptor';
import { LoggedInRouteGuardLogic } from './routing/route-guards/logged-in.guard';
import { LogoComponent } from './components/logo/logo.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RedirectAfterService } from './routing/redirect-after.service';
import { FeaturePermissionEnabledDirective } from './directives/feature-permission-enabled.directive';
import { FeaturePermissionsRouteGuardLogic } from './routing/route-guards/feature-permissions.guard';
import { SupportButtonComponent } from './components/support-button/support-button.component';
import { LoaderContentDirective } from './directives/loader-content.directive';
import { LoggedInRegisterRouteGuardLogic } from './routing/route-guards/logged-in-register.guard';
import { FeaturePermissionsEnabledDirective } from './directives/feature-permissions-enabled.directive';
import { FeatureDisabledDirective } from './directives/feature-disabled.directive';
import { MatButtonModule } from '@angular/material/button';
import { CoolLoadingButtonModule } from '@angular-cool/loading-button';
import { DiscountsService } from './services/discounts.service';
import { ShopService } from './services/shop.service';
import { AsPipe } from './pipes/as.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,

    FontAwesomeModule,

    CoolHttpModule,
    CoolStorageModule,
    CoolLoadingButtonModule,

    MatButtonModule,
    MatProgressSpinnerModule,

    TranslateModule.forChild(),

    NgOptimizedImage,
  ],
  declarations: [
    LoadingIndicatorComponent,
    LogoComponent,
    FeaturePermissionEnabledDirective,
    FeatureDisabledDirective,
    FeaturePermissionsEnabledDirective,
    SupportButtonComponent,
    LoaderContentDirective,
    AsPipe,
  ],
  exports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,

    FontAwesomeModule,

    NgOptimizedImage,

    MatButtonModule,
    CoolHttpModule,
    CoolStorageModule,

    CoolLoadingButtonModule,

    MatProgressSpinnerModule,

    TranslateModule,

    LoadingIndicatorComponent,
    LogoComponent,
    FeaturePermissionEnabledDirective,
    FeatureDisabledDirective,
    FeaturePermissionsEnabledDirective,
    SupportButtonComponent,
    LoaderContentDirective,
    AsPipe,
  ],
})
export class AppCommonModule {
  public static forRoot(): ModuleWithProviders<AppCommonModule> {
    return {
      ngModule: AppCommonModule,
      providers: [
        LoggerService,
        ShopRouteNavigationService,
        RedirectAfterService,
        AuthenticationService,

        DateProvider,

        DiscountsService,
        ShopService,

        TranslationService,
        StartupService,
        UnauthenticatedInterceptor,

        LoggedInRouteGuardLogic,
        LoggedInRegisterRouteGuardLogic,
        FeaturePermissionsRouteGuardLogic,

        ...CoolHttpModule.forRoot().providers as Provider[],
        ...CoolStorageModule.forRoot().providers as Provider[],

        { provide: ENVIRONMENT, useValue: environment },
        { provide: WINDOW, useValue: window },
        { provide: ErrorHandler, useClass: environment.logging?.enabled ? GlobalErrorHandler : ErrorHandler },
        { provide: APP_INITIALIZER, useFactory: startupServiceFactory, deps: [StartupService], multi: true },
      ],
    };
  }
}
