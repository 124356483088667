import { Injectable } from '@angular/core';
import {
  CouponDTO,
  CouponsWithBrands,
  DiscountId,
  DiscountsWithBrandsDTO,
  DiscountWithBrandDTO,
} from '../../../../../common/dto/discount.dto';
import { CoolHttp } from '@angular-cool/http';
import { PCacheable, PCacheBuster } from 'ts-cacheable';
import { MINUTE_IN_MILLISECONDS } from '../../../../../common/utils/date.utils';
import { Subject } from 'rxjs';

export const couponCacheBuster$ = new Subject<void>();

@Injectable()
export class DiscountsService {
  constructor(
    private _http: CoolHttp,
  ) {}

  @PCacheable({
    maxAge: 5 * MINUTE_IN_MILLISECONDS,
    cacheBusterObserver: couponCacheBuster$,
  })
  public async getDiscountsAsyncCACHED(skip: number, limit: number): Promise<DiscountsWithBrandsDTO> {
    return this._http.getAsync('api/shop/discounts', {
      params: {
        skip: skip.toString(),
        limit: limit.toString(),
      },
    });
  }

  @PCacheable({
    maxAge: 5 * MINUTE_IN_MILLISECONDS,
    cacheBusterObserver: couponCacheBuster$,
  })
  public async getDiscountByIdAsync(discountId: DiscountId): Promise<DiscountWithBrandDTO> {
    return await this._http.getAsync(`api/shop/discounts/${ discountId }`);
  }

  @PCacheBuster({
    cacheBusterNotifier: couponCacheBuster$,
  })
  public async generateCouponAsync(discountId: DiscountId): Promise<CouponDTO> {
    return await this._http.postAsync('api/shop/coupons', {
      discountId: discountId,
    });
  }

  @PCacheable({
    maxAge: 5 * MINUTE_IN_MILLISECONDS,
    cacheBusterObserver: couponCacheBuster$,
  })
  public async getMyCouponsAsync(): Promise<CouponsWithBrands> {
    return await this._http.getAsync('api/shop/coupons');
  }
}
