import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ShopRouteLocations, ShopRouteQueryParams } from './route-locations.enum';
import { RedirectAfterService } from './redirect-after.service';
import { DiscountId } from '../../../../../common/dto/discount.dto';

@Injectable()
export class ShopRouteNavigationService {
  constructor(
    private _router: Router,
    private _redirectAfterService: RedirectAfterService,
  ) {}

  public async goToLoginAsync(redirectToCurrentUrlAfterLogin: boolean = false) {
    if (redirectToCurrentUrlAfterLogin) {
      this._redirectAfterService.saveCurrentUrlForRedirect();
    }

    await this._router.navigateByUrl(ShopRouteLocations.Login.absoluteUrl);
  }

  public async goToRegistrationAsync(redirectToCurrentUrlAfterLogin: boolean = false) {
    if (redirectToCurrentUrlAfterLogin) {
      this._redirectAfterService.saveCurrentUrlForRedirect();
    }

    await this._router.navigateByUrl(ShopRouteLocations.Login.getAbsoluteUrlWithQuery({
      register: '1',
    }));
  }

  public async goToDashboardAsync() {
    await this._router.navigateByUrl(ShopRouteLocations.Dashboard.absoluteUrl);
  }

  public async goToDashboardHomeAsync() {
    await this._router.navigateByUrl(ShopRouteLocations.Dashboard.children.DashboardHome.absoluteUrl, {
      replaceUrl: true,
    });
  }

  public async goToDiscountAsync(discountId: DiscountId) {
    await this._router.navigateByUrl(ShopRouteLocations.Dashboard.children.DashboardHome.getAbsoluteUrlWithQuery({
      [ShopRouteQueryParams.Discount]: discountId,
    }));
  }

  public async goToDashboardNotFoundAsync() {
    await this._router.navigateByUrl(ShopRouteLocations.Dashboard.children.NotFound.absoluteUrl);
  }

  public async replaceQueryParamsAsync(activatedRoute: ActivatedRoute, queryParams: Params | null) {
    await this._router.navigate(
      [],
      {
        relativeTo: activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: '',
      },
    );
  }
}
