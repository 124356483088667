import { RouteLocationNode } from '../../../../../common/model/route-location-node';


export const ShopRouteLocations = {
  Dashboard: new RouteLocationNode('dashboard', {
    DashboardHome: new RouteLocationNode(''),
    MyCoupons: new RouteLocationNode('my-coupons'),

    NotFound: new RouteLocationNode('not-found'),
  }),

  Login: new RouteLocationNode('login', {
    LoginPage: new RouteLocationNode(''),
    RegistrationPage: new RouteLocationNode('registration'),
    RegistrationSuccessfulPage: new RouteLocationNode('registration-successful'),
    ValidationPage: new RouteLocationNode('validate'),

    ForgotPassword: new RouteLocationNode('forgot-password'),
    ResetPassword: new RouteLocationNode('reset-password'),
  }),

  ClaimCredit: new RouteLocationNode('claim-credit'),

  Maintenance: new RouteLocationNode('maintenance'),

  NotFound: new RouteLocationNode('not-found'),
};

export const ShopRouteQueryParams = {
  Discount: 'discount',
};
