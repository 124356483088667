import { Injectable } from '@angular/core';
import { CoolHttp } from '@angular-cool/http';
import { ShopBalanceCreditClaimResponse } from '../../../../../common/dto/shop.dto';
import { AccountId } from '../../../../../common/dto/account.dto';

@Injectable()
export class ShopService {
  constructor(
    private _http: CoolHttp,
  ) {}

  public async claimCreditsAsync(token: string): Promise<ShopBalanceCreditClaimResponse> {
    return await this._http.postAsync('api/shop/balance/claims', {
      token: token,
    });
  }

  public async getBlockedAccountsInWindowAsync(): Promise<{ accountIds: AccountId[] }> {
    return await this._http.getAsync('api/shop/balance/claims/blocked-accounts-in-window');
  }
}
