import { inject, Injectable } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store } from '@ngxs/store';
import { ShopAppStateModel } from '../../../shop-app.state';
import { AuthenticationService } from '../../services/authentication.service';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ShopRouteNavigationService } from '../shop-route-navigation.service';

@Injectable()
export class LoggedInRegisterRouteGuardLogic {
  constructor(
    private _store: Store,
    private _authenticationService: AuthenticationService,
    private _routeNavigationService: ShopRouteNavigationService,
  ) {}

  public canActivate(): Observable<boolean> {
    return this._store
      .select<ShopAppStateModel>((state) => state.ShopAppStateModel)
      .pipe(
        filter((state: ShopAppStateModel) => !!state?.isInitialized),
        take(1),
        map((applicationState: ShopAppStateModel) => {
          if (!applicationState.isUserLoggedIn) {
            this._routeNavigationService.goToRegistrationAsync(true);
          }

          return applicationState.isUserLoggedIn;
        }),
      );
  }
}

export const LoggedInRegisterRouteGuard: CanActivateFn = (): Observable<boolean> => {
  return inject(LoggedInRegisterRouteGuardLogic).canActivate();
};
