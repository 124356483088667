import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UserSession } from '../../../common/dto/user-session.dto';
import { DiscountId } from '../../../common/dto/discount.dto';

export interface ShopAppStateModel {
  isInitialized: boolean;

  isUserLoggedIn: boolean;

  session: UserSession | undefined;

  discountDialogId: DiscountId | undefined;
}

export class SetIsInitializedAction {
  static readonly type = 'Set Is initialized Action';
}

export class SetSessionAction {
  static readonly type = 'Set Session Action';

  constructor(public session: UserSession | undefined) {
  }
}

export class SetDiscountDialogIdAction {
  static readonly type = 'Set Discount Dialog Id Action';

  constructor(public discountDialogId: DiscountId | undefined) {
  }
}

@State<ShopAppStateModel>({
  name: 'ShopAppStateModel',
  defaults: {
    isInitialized: false,
    isUserLoggedIn: false,
    session: undefined,
    discountDialogId: undefined,
  },
})
@Injectable()
export class ShopAppState {
  @Action(SetIsInitializedAction)
  setInitialized(ctx: StateContext<ShopAppStateModel>) {
    return ctx.patchState({
      isInitialized: true,
    });
  }

  @Action(SetSessionAction)
  setSession(ctx: StateContext<ShopAppStateModel>, action: SetSessionAction) {
    return ctx.patchState({
      isUserLoggedIn: action.session !== undefined,
      session: action.session,
    });
  }

  @Action(SetDiscountDialogIdAction)
  setDiscountDialogId(ctx: StateContext<ShopAppStateModel>, action: SetDiscountDialogIdAction) {
    return ctx.patchState({
      discountDialogId: action.discountDialogId,
    });
  }

  @Selector()
  static isUserLoggedIn(state: ShopAppStateModel) {
    return state.isUserLoggedIn;
  }

  @Selector()
  static session(state: ShopAppStateModel) {
    return state.session;
  }
}
